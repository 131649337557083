<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Konfigurasi Matrix Approval",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Konfigurasi Matrix Approval",
            items: [
                {
                    text: "Master",
                    href: "/",
                },
                {
                    text: "Konfigurasi Matrix Approval",
                    active: true,
                },
            ],
            //

            axiosCatchError: null,
            // Componen v-select
            master_status_permohonan: [],
            master_role: [],
            master_kategori_status: ["Draft", "Pengajuan", "Proses", "Selesai", "Perbaikan", "Dibatalkan"],
            master_type_status: ["Work Permit Request", "Work Permit Closing"],
            // Componen Matrix
            mps_id: this.$route.params.mps_id,
            data_perizinan: [],
            row_matrix: [],
        };
    },
    mounted() {
        // Load data awal banget
        let self = this;

        Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
        });
        // Get Master Status Permohonan
        axios
            .get(process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-status-permohonan", {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            })
            .then((response) => {
                this.master_status_permohonan = response.data.data.referensi;
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                Swal.close();
            });
        // Get Master Role
        axios
            .get(process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-role", {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            })
            .then((response) => {
                this.master_role = response.data.data.referensi;
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                Swal.close();
            });
        // get data berdasarkan id
        axios
            .get(process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub/get-matrix-approval", {
                params: {
                    mps_id: self.mps_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            })
            .then(function (response) {
                console.log(response);
                var response_data = response.data;
                var response_data_fix = response_data.data[0];
                if (response_data.meta.code == 200) {
                    self.data_perizinan = response_data_fix;
                    self.row_matrix = response_data_fix.status_permohonan_konfig;
                    Swal.close();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data_fix.data.message,
                    });
                }
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                Swal.close();
            });
    },
    methods: {
        addRowMatrix() {
            this.row_matrix.push({
                mspk_id: null,
                status_permohonan: null,
                mspk_urutan: null,
                role: null,
                mspk_status_kategori: null,
            });
        },
        removeMatrix(key_deleted) {
            this.row_matrix.splice(key_deleted, 1);
        },
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            axios
                .post(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub/store-matrix-approval",
                    {
                        perizinan_sub_id: self.data_perizinan.mps_id,
                        row_matrix: JSON.stringify(self.row_matrix),
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    console.log(response);
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Halaman akan di muat ulang.",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            // self.$router.push({ name: "all-sub-perizinan" });
                            self.$router.push({ name: "work-permit-config" });
                        }
                    });
                })
                .catch((e) => {
                    this.axiosCatchError = e.response.data.data;
                    Swal.close();
                });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="card-title">Semua Data Konfigurasi Matrix Approval</div>
                                </div>
                                <div class="col-md-6">
                                    <div class="text-end"></div>
                                </div>
                                <div class="col-md-12">
                                    <div v-if="axiosCatchError">
                                        <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                            {{ axiosCatchError }}
                                        </div>
                                        <div class="alert alert-danger" role="alert" v-else>
                                            <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                                <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                                    <span class="text-danger">{{ allErrors[0] }} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <table class="table table-bordered table-sm">
                                        <thead class="table-info">
                                            <tr>
                                                <th>No</th>
                                                <th style="width: 350px">Nama Status</th>
                                                <th>Role</th>
                                                <th>Tipe Status</th>
                                                <th>Kategori Status</th>
                                                <th>Proses Terima/Tolak</th>
                                                <th>Urutan</th>
                                                <th>
                                                    <div class="btn btn-success btn-sm" v-on:click="addRowMatrix()">
                                                        <i class="bx bx-plus"></i>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row_matrix_value, row_matrix_key) in row_matrix" :key="row_matrix_key">
                                                <td>{{ row_matrix_key + 1 }}</td>
                                                <td>
                                                    <v-select :options="master_status_permohonan" label="msp_nama" v-model="row_matrix_value.status_permohonan" placeholder="Pilih Status Permohonan"></v-select>
                                                </td>
                                                <td>
                                                    <v-select :options="master_role" label="role" v-model="row_matrix_value.role" placeholder="Pilih Role Proses"></v-select>
                                                </td>
                                                <td>
                                                    <v-select :options="master_type_status" v-model="row_matrix_value.mspk_status_type" placeholder="Pilih Tipe Status"></v-select>
                                                </td>
                                                <td>
                                                    <v-select :options="master_kategori_status" v-model="row_matrix_value.mspk_status_kategori" placeholder="Pilih Kategori Status"></v-select>
                                                </td>
                                                <td>
                                                    <b-form-checkbox v-model="row_matrix_value.is_approval" name="is_approval" value="t" unchecked-value="f"></b-form-checkbox>
                                                </td>
                                                <td>
                                                    <b-form-input type="number" v-model="row_matrix_value.mspk_urutan" placeholder="Urutan"></b-form-input>
                                                </td>
                                                <td>
                                                    <div class="btn btn-danger btn-sm" v-on:click="removeMatrix(row_matrix_key)">
                                                        <i class="bx bx-minus"></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-end">
                                <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
